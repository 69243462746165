import {
  getPaginated,
  PaginatedRequest,
  PaginatedResponse,
} from "@/application/api/getPaginated";
import { AxiosResponse } from "axios";
import mapIndexResponseErrors from "@/application/util/mapIndexResponseErrors";

export interface AddressBookIndexItem {
  id: number;
  clientId: number;
  invoiceAddress: {
    companyName: string;
    department: string;
    firstName: string;
    lastName: string;
    country: string;
    postalCode: string;
    houseNumber: string;
    houseNumberAddition: string;
    isWithoutPostalCode: boolean;
    street: string;
    addressLine: string;
    city: string;
    address: string;
    email: string;
    phone: string;
  };
  useDeliveryAsInvoiceAddress: boolean;
  deliveryAddress: {
    companyName: string;
    department: string;
    firstName: string;
    lastName: string;
    country: string;
    postalCode: string;
    houseNumber: string;
    houseNumberAddition: string;
    isWithoutPostalCode: boolean;
    street: string;
    addressLine: string;
    city: string;
    address: string;
    email: string;
    phone: string;
  };
}
export const addressBookIndex = async (
  data: PaginatedRequest,
  clientId: number
): Promise<AxiosResponse<PaginatedResponse<AddressBookIndexItem[]>>> => {
  const response = await getPaginated(`client/${clientId}/address-book`, data);
  response.data.data = mapIndexResponseErrors(response.data.data);

  return response;
};
